// Domains.js

import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Button, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Box, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  IconButton, 
  Tooltip,
  Snackbar,
  Alert
} from '@mui/material';
import { CheckCircle, Error, Info, FileCopy, Delete } from '@mui/icons-material';
import axios from 'axios';

const Domains = () => {
  const [domain, setDomain] = useState('');
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchDomains();
  }, []);

  useEffect(() => {
    console.log('Fetched domains:', domains);
  }, [domains]);

  const fetchDomains = async () => {
    try {
      const response = await axios.get('/api/domains');
      console.log('Response from /api/domains:', response.data);
      if (Array.isArray(response.data)) {
        setDomains(response.data);
      } else {
        console.error('Response data is not an array:', response.data);
        setDomains([]);
      }
    } catch (error) {
      console.error('Failed to fetch domains', error);
      setErrorMessage('Failed to fetch domains.');
    }
  };

  const handleAddDomain = async () => {
    try {
      if (!domain) {
        setErrorMessage('Please enter a domain.');
        return;
      }
      const response = await axios.post('/api/domains', { domain });
      setDomains([...domains, response.data]);
      setDomain('');
      setSuccessMessage('Domain added successfully.');
    } catch (error) {
      console.error('Failed to add domain', error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(`Error: ${error.response.data.error}`);
      } else {
        setErrorMessage('Failed to add domain. Please try again later.');
      }
    }
  };

  const handleOpenDialog = (d) => {
    setSelectedDomain(d);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedDomain(null);
  };

  const handleOpenConfirmDialog = (d) => {
    setSelectedDomain(d);
    setConfirmOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmOpen(false);
    setSelectedDomain(null);
  };

  const handleDeleteDomain = async () => {
    try {
      await axios.delete(`/api/domains/${selectedDomain._id}`);
      setDomains(domains.filter(d => d._id !== selectedDomain._id));
      setConfirmOpen(false);
      setSelectedDomain(null);
      setSuccessMessage('Domain deleted successfully.');
    } catch (error) {
      console.error('Failed to delete domain', error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(`Error: ${error.response.data.error}`);
      } else {
        setErrorMessage('Failed to delete domain. Please try again later.');
      }
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setSuccessMessage('Copied to clipboard!');
    }, (err) => {
      console.error('Failed to copy: ', err);
      setErrorMessage('Failed to copy text.');
    });
  };
  
  const handleCheckSpf = async (domain) => {
    try {
      await axios.post('/api/domains/check-spf', { domain });
      setDomains(domains.map(d => d.domain === domain ? { ...d, spfStatus: true } : d));
      setSuccessMessage(`SPF record for ${domain} is valid.`);
    } catch (error) {
      console.error(`Failed to validate SPF for domain ${domain}`, error);
      setErrorMessage(`Failed to validate SPF for ${domain}.`);
    }
  };

  const handleCheckDkim = async (domain) => {
    try {
      await axios.post('/api/domains/check-dkim', { domain });
      setDomains(domains.map(d => d.domain === domain ? { ...d, dkimStatus: true } : d));
      setSuccessMessage(`DKIM record for ${domain} is valid.`);
    } catch (error) {
      console.error(`Failed to validate DKIM for domain ${domain}`, error);
      setErrorMessage(`Failed to validate DKIM for ${domain}.`);
    }
  };

  // == DMARC ADDED ==
  const handleCheckDmarc = async (domain) => {
    try {
      // ?????? ? ?????? API ?? ???????? DMARC
      // ??????: await axios.post('/api/domains/check-dmarc', { domain });
      await axios.post('/api/domains/check-dmarc', { domain });

      // ????????? ?????? ?????? ? ?????????
      setDomains(domains.map(d => d.domain === domain ? { ...d, dmarcStatus: true } : d));
      setSuccessMessage(`DMARC record for ${domain} is valid.`);
    } catch (error) {
      console.error(`Failed to validate DMARC for domain ${domain}`, error);
      setErrorMessage(`Failed to validate DMARC for ${domain}.`);
    }
  };
  
  const handleCheckBimi = async (domain) => {
  try {
    const response = await axios.post('/api/domains/check-bimi', { domain });
    // ???? success (200), ?????? bimiStatus = true
    setDomains(domains.map(d => d.domain === domain ? { ...d, bimiStatus: true } : d));
    setSuccessMessage(`BIMI record for ${domain} is valid.`);
  } catch (error) {
    console.error(`Failed to validate BIMI for domain ${domain}`, error);
    // ???? 400 ??? 500, ?????? bimiStatus = false
    setDomains(domains.map(d => d.domain === domain ? { ...d, bimiStatus: false } : d));
    setErrorMessage(`Failed to validate BIMI for ${domain}.`);
  }
};

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Security Settings</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField 
          label="Domain" 
          value={domain} 
          onChange={(e) => setDomain(e.target.value)} 
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" color="primary" onClick={handleAddDomain}>Add Domain</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>SPF Record</TableCell>
              <TableCell>SPF Status</TableCell>
              <TableCell>DKIM Host</TableCell>
              <TableCell>DKIM Record</TableCell>
              <TableCell>DKIM Status</TableCell>
              {/* == DMARC ADDED == */}
              <TableCell>DMARC Record</TableCell>
              <TableCell>DMARC Status</TableCell>
              <TableCell>BIMI Record</TableCell>
              <TableCell>BIMI Status</TableCell>
              {/* == /DMARC ADDED == */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(domains) && domains.length > 0 ? (
              domains.map((d) => (
                <TableRow key={d._id}>
                  <TableCell>{d.domain}</TableCell>
                  
                  {/* SPF */}
                  <TableCell>
                    <Box display="flex" alignItems="center" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                      {d.spfRecord || 'N/A'}
                      {d.spfRecord && (
                        <>
                          <Tooltip title="Copy SPF Record">
                            <IconButton onClick={() => copyToClipboard(d.spfRecord)} size="small">
                              <FileCopy />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Check SPF Status">
                            <Button 
                              onClick={() => handleCheckSpf(d.domain)} 
                              variant="contained" 
                              color="secondary" 
                              size="small"
                            >
                              Check
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {d.spfStatus 
                      ? <CheckCircle color="primary" /> 
                      : <Error color="error" />
                    }
                  </TableCell>

                  {/* DKIM */}
                  <TableCell>{d.dkimHost || 'N/A'}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                      {d.dkimRecord || 'N/A'}
                      {d.dkimRecord && (
                        <>
                          <Tooltip title="Copy DKIM Record">
                            <IconButton onClick={() => copyToClipboard(d.dkimRecord)} size="small">
                              <FileCopy />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Check DKIM Status">
                            <Button 
                              onClick={() => handleCheckDkim(d.domain)} 
                              variant="contained" 
                              color="secondary" 
                              size="small"
                            >
                              Check
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {d.dkimStatus 
                      ? <CheckCircle color="primary" /> 
                      : <Error color="error" />
                    }
                  </TableCell>

                  {/* == DMARC ADDED == */}
                  <TableCell>
                    <Box display="flex" alignItems="center" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                      {d.dmarcRecord || 'N/A'}
                      {d.dmarcRecord && (
                        <>
                          <Tooltip title="Copy DMARC Record">
                            <IconButton onClick={() => copyToClipboard(d.dmarcRecord)} size="small">
                              <FileCopy />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Check DMARC Status">
                            <Button 
                              onClick={() => handleCheckDmarc(d.domain)} 
                              variant="contained" 
                              color="secondary" 
                              size="small"
                            >
                              Check
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  </TableCell>
                  
                  
                  <TableCell>
                    {d.dmarcStatus 
                      ? <CheckCircle color="primary" /> 
                      : <Error color="error" />
                    }
                  </TableCell>
                  
                  {/* BIMI Record */}
  <TableCell>
    <Box display="flex" alignItems="center" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
      {d.bimiRecord || 'N/A'}
      {d.bimiRecord && (
        <>
          <Tooltip title="Copy BIMI Record">
            <IconButton onClick={() => copyToClipboard(d.bimiRecord)} size="small">
              <FileCopy />
            </IconButton>
          </Tooltip>
          <Tooltip title="Check BIMI Status">
            <Button 
              onClick={() => handleCheckBimi(d.domain)} 
              variant="contained" 
              color="secondary" 
              size="small"
              style={{ marginLeft: '10px' }}
            >
              Check
            </Button>
          </Tooltip>
        </>
      )}
    </Box>
  </TableCell>

  {/* BIMI Status (?????? ??????/??????) */}
  <TableCell>
    {d.bimiStatus 
      ? <CheckCircle color="primary" /> 
      : <Error color="error" />
    }
  </TableCell>
                  {/* == /DMARC ADDED == */}

                  <TableCell>
                    <Tooltip title="View Details">
                      <IconButton onClick={() => handleOpenDialog(d)} style={{ marginLeft: '10px' }}>
                        <Info />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Domain">
                      <IconButton onClick={() => handleOpenConfirmDialog(d)} style={{ marginLeft: '10px' }}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  
                </TableRow>
              ))
            ) : (
              <TableRow>
                {/* ?????? colspan (?????? ???????? ??????) */}
                <TableCell colSpan={9} align="center">
                  No domains found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* ?????? ? ???????? ?????? */}
      {selectedDomain && (
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>Domain Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>Domain:</strong> {selectedDomain.domain}<br />
              <strong>SPF Record:</strong> {selectedDomain.spfRecord || 'N/A'}<br />
              <strong>SPF Status:</strong> {selectedDomain.spfStatus ? 'Valid' : 'Invalid'}<br />
              <strong>DKIM Host:</strong> {selectedDomain.dkimHost || 'N/A'}<br />
              <strong>DKIM Record:</strong> {selectedDomain.dkimRecord || 'N/A'}<br />
              <strong>DKIM Status:</strong> {selectedDomain.dkimStatus ? 'Valid' : 'Invalid'}<br />
              {/* == DMARC ADDED == */}
              <strong>DMARC Record:</strong> {selectedDomain.dmarcRecord || 'N/A'}<br />
              <strong>DMARC Status:</strong> {selectedDomain.dmarcStatus ? 'Valid' : 'Invalid'}
            </DialogContentText>

            <Typography variant="body1" gutterBottom>
              <strong>SPF Setup Instructions:</strong><br />
              {selectedDomain.spfRecord ? (
                <div>
                  Add the following SPF record to your DNS settings:<br />
                  <code>{selectedDomain.spfRecord}</code>
                </div>
              ) : 'SPF record not generated yet.'}
            </Typography>

            <Typography variant="body1" gutterBottom>
              <strong>DKIM Setup Instructions:</strong><br />
              {selectedDomain.dkimRecord ? (
                <div>
                  Add the following DKIM record to your DNS settings:<br />
                  <code>{selectedDomain.dkimRecord}</code>
                </div>
              ) : 'DKIM record not generated yet.'}
            </Typography>

            {/* == DMARC ADDED == */}
            <Typography variant="body1" gutterBottom>
              <strong>DMARC Setup Instructions:</strong><br />
              {selectedDomain.dmarcRecord ? (
                <div>
                  Add the following DMARC record to your DNS settings:<br />
                  <code>{selectedDomain.dmarcRecord}</code>
                </div>
              ) : 'DMARC record not generated yet.'}
            </Typography>
            {/* == /DMARC ADDED == */}

          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* ?????? ????????????? ???????? */}
      <Dialog
        open={confirmOpen}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the domain <strong>{selectedDomain?.domain}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">Cancel</Button>
          <Button onClick={handleDeleteDomain} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar ??? successMessage */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Snackbar ??? errorMessage */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Domains;
