// src/components/ContactList.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Paper, Typography, Box, Button, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Dialog, DialogContent, DialogTitle, 
  DialogActions, TextField, Select, MenuItem, IconButton, Pagination, Backdrop, CircularProgress 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import * as XLSX from 'xlsx';

const CATEGORIES = [
  'Construction', 
  'IT', 
  'Recruiting', 
  'Marketing', 
  'Finance', 
  'Healthcare', 
  'Education',
  'Manufacturing',
  'Retail',
  'Hospitality',
  'Transportation',
  'Telecommunications',
  'Energy',
  'Consulting'
];

const ContactList = () => {
  const { groupName } = useParams();  
  const navigate = useNavigate();

  // Состояния для списка групп
  const [groups, setGroups] = useState([]);
  
  // Состояния для фильтрации групп
  const [groupNameFilter, setGroupNameFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);

  // Состояния для импорта
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [importedContacts, setImportedContacts] = useState([]);
  const [importGroupName, setImportGroupName] = useState('');
  const [importCategory, setImportCategory] = useState('');

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupCategory, setNewGroupCategory] = useState('');
  
  // Состояние диалога удаления группы
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({open: false, groupId: null});

  // Состояния для деталей группы (контактов)
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    email: '',
    status: ''
  });
  const [page, setPage] = useState(1);
  const rowsPerPage = 50;

  // Новые состояния для добавления и импорта в группу
  const [openAddEmailDialog, setOpenAddEmailDialog] = useState(false);
  const [newEmails, setNewEmails] = useState('');

  const [openImportGroupDialog, setOpenImportGroupDialog] = useState(false);
  const [importedGroupContacts, setImportedGroupContacts] = useState([]);
  // const [importGroupCategory, setImportGroupCategory] = useState(''); // Не используется, можно удалить

  // Новые состояния для индикации загрузки
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [loadingAddEmails, setLoadingAddEmails] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);

  useEffect(() => {
    if (!groupName) {
      // Если нет groupId, значит мы на странице со списком групп
      fetchGroups();
    } else {
      // Если есть groupId, значит мы на странице деталей группы
      fetchContacts();
    }
  }, [groupName]);

  // Получаем список групп
  const fetchGroups = async () => {
    setLoadingGroups(true); // Начало загрузки
    try {
      const response = await axios.get('/api/contacts/groups');
      console.log('Fetched groups:', response.data); // Посмотрим, что приходит
      setGroups(response.data);
    } catch (error) {
      console.error('Failed to fetch groups:', error.message);
      alert(`Failed to fetch groups: ${error.response?.data?.error || error.message}`);
    } finally {
      setLoadingGroups(false); // Конец загрузки
    }
  };

  // Получаем контакты для конкретной группы
  const fetchContacts = async () => {
    setLoadingContacts(true); // Начало загрузки
    try {
      const response = await axios.get(`/api/contacts?groupId=${groupName}`);
      setContacts(response.data);
    } catch (error) {
      console.error('Failed to fetch contacts', error);
    } finally {
      setLoadingContacts(false); // Конец загрузки
    }
  };

  // Обработка выбора файла для импорта групп
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (evt) => {
      const data = new Uint8Array(evt.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const imported = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const formattedContacts = imported.map(row => ({
        email: row[0]
      })).filter(c => c.email);
      setImportedContacts(formattedContacts);
      setOpenImportDialog(true);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleUnsubscribe = async (email) => {
    try {
      await axios.post('/api/contacts/unsubscribe', { email });
      alert(`${email} unsubscribed successfully.`);
      
      // Добавляем небольшую задержку перед `fetchContacts()`, чтобы бэкенд успел обновить базу
      setTimeout(() => fetchContacts(), 500);
    } catch (error) {
      console.error("Failed to unsubscribe:", error);
      alert("Failed to unsubscribe.");
    }
  };

  // Импорт контактов (создание новой группы)
  const handleImportSubmit = async () => {
    setLoadingImport(true); // Начало загрузки
    if (!importGroupName || !importCategory) {
      alert('Please specify group name and category.');
      setLoadingImport(false);
      return;
    }
    if (importedContacts.length === 0) {
      alert('No contacts to import.');
      setLoadingImport(false);
      return;
    }

    // Убедимся, что массив содержит только строки email
    const formattedEmails = importedContacts.map(contact => {
      if (typeof contact === "string") return contact.trim();
      if (typeof contact === "object" && contact.email) return contact.email.trim();
      return ""; // Игнорируем некорректные данные
    }).filter(email => email !== ""); // Убираем пустые значения

    try {
      await axios.post('/api/contacts/import', {
        group: importGroupName,  
        category: importCategory,  
        emails: formattedEmails,  // Теперь точно массив строк
      });

      setOpenImportDialog(false);
      setImportedContacts([]);
      setImportGroupName('');
      setImportCategory('');

      alert('Contacts imported successfully.');
      fetchGroups();
    } catch (error) {
      console.error('Failed to import contacts:', error);
      alert('Failed to import contacts. Please try again.');
    } finally {
      setLoadingImport(false); // Конец загрузки
    }
  };

  const handleCreateGroup = async () => {
    setLoadingGroups(true); // Начало загрузки
    if (!newGroupName.trim() || !newGroupCategory.trim()) {
      alert('Please enter a valid group name and category.');
      setLoadingGroups(false);
      return;
    }

    console.log("Creating group:", { name: newGroupName, category: newGroupCategory });

    try {
      await axios.post('/api/contacts/groups', { name: newGroupName, category: newGroupCategory });
      setOpenCreateDialog(false);
      setNewGroupName('');
      setNewGroupCategory('');
      alert('Group created successfully.');
      fetchGroups(); // Обновляем список групп
    } catch (error) {
      console.error('Failed to create group:', error);
      alert('Failed to create group.');
    } finally {
      setLoadingGroups(false); // Конец загрузки
    }
  };

  // Удаление группы
  const handleDeleteGroup = async () => {
    if (!confirmDeleteDialog.groupId) return;

    console.log(`Deleting group: "${confirmDeleteDialog.groupId}"`);

    setLoadingGroups(true); // Начало загрузки
    try {
      await axios.delete(`/api/contacts/groups/${encodeURIComponent(confirmDeleteDialog.groupId.trim())}`);
      setConfirmDeleteDialog({ open: false, groupId: null });
      fetchGroups(); // Обновляем список после удаления
    } catch (error) {
      console.error('Failed to delete group', error);
      alert('Failed to delete group.');
    } finally {
      setLoadingGroups(false); // Конец загрузки
    }
  };

  // Удаление контакта
  const handleDeleteContact = async (id) => {
    try {
      await axios.delete(`/api/contacts/${id}`);
      fetchContacts();
    } catch (error) {
      console.error('Failed to delete contact:', error);
    }
  };

  // Фильтры для контактов
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  };

  // Фильтрация контактов
  const filteredContacts = contacts.filter(contact =>
    contact.email.includes(search) &&
    (filter.email ? contact.email.includes(filter.email) : true) &&
    (filter.status ? contact.status === filter.status : true)
  );

  const paginatedContacts = filteredContacts.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  
  const updateContactStatus = async (id, newStatus) => {
    try {
      if (newStatus === 'unsubscribed') {
        // Исправлено: Передаём id вместо email
        await axios.post('/api/contacts/unsubscribe', { id }); 
      } else {
        await axios.put(`/api/contacts/${id}/status`, { status: newStatus });
      }

      setContacts((prevContacts) =>
        prevContacts.map((contact) =>
          contact._id === id ? { ...contact, status: newStatus } : contact
        )
      );
      alert('Status updated successfully.');
    } catch (error) {
      console.error('Failed to update contact status:', error);
      if (error.response) {
        // Сервер ответил с ошибкой
        alert(`Failed to update contact status: ${error.response.data.error || error.message}`);
      } else if (error.request) {
        // Запрос был сделан, но ответ не получен
        alert('No response from server. Please try again later.');
      } else {
        // Произошла ошибка при настройке запроса
        alert(`Error: ${error.message}`);
      }
    }
  };

  // Новые функции для добавления и импорта в существующую группу

  // Функция для добавления email вручную
  const handleAddEmails = async () => {
    setLoadingAddEmails(true); // Начало загрузки
    const emailsArray = newEmails.split(',').map(email => email.trim()).filter(email => email !== '');
    if (emailsArray.length === 0) {
      alert('Please enter at least one valid email.');
      setLoadingAddEmails(false);
      return;
    }

    // Валидация email-адресов
    const invalidEmails = emailsArray.filter(email => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
    if (invalidEmails.length > 0) {
      alert(`Invalid email formats: ${invalidEmails.join(', ')}`);
      setLoadingAddEmails(false);
      return;
    }

    try {
      await axios.post('/api/contacts/groups/add-emails', {
        groupName,
        emails: emailsArray,
      });
      alert('Emails added successfully.');
      setOpenAddEmailDialog(false);
      setNewEmails('');
      fetchContacts();
    } catch (error) {
      console.error('Failed to add emails:', error);
      alert(`Failed to add emails: ${error.response?.data?.error || error.message}`);
    } finally {
      setLoadingAddEmails(false); // Конец загрузки
    }
  };

  // Функция для обработки выбора файла для импорта в группу
  const handleFileSelectGroupImport = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (evt) => {
      const data = new Uint8Array(evt.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const imported = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const formattedContacts = imported.map(row => ({
        email: row[0]
      })).filter(c => c.email);
      setImportedGroupContacts(formattedContacts);
      setOpenImportGroupDialog(true);
    };
    reader.readAsArrayBuffer(file);
  };

  // Функция для отправки импорта контактов в группу
  const handleImportGroupSubmit = async () => {
    setLoadingImport(true); // Начало загрузки
    if (importedGroupContacts.length === 0) {
      alert('No contacts to import.');
      setLoadingImport(false);
      return;
    }

    const formattedEmails = importedGroupContacts.map(contact => {
      if (typeof contact === "string") return contact.trim();
      if (typeof contact === "object" && contact.email) return contact.email.trim();
      return "";
    }).filter(email => email !== "");

    // Валидация email-адресов
    const invalidEmails = formattedEmails.filter(email => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
    if (invalidEmails.length > 0) {
      alert(`Invalid email formats: ${invalidEmails.join(', ')}`);
      setLoadingImport(false);
      return;
    }

    try {
      await axios.post('/api/contacts/groups/import-emails', {
        groupName,
        emails: formattedEmails,
      });

      setOpenImportGroupDialog(false);
      setImportedGroupContacts([]);
      // setImportGroupCategory(''); // Не используется, можно удалить

      alert('Contacts imported successfully.');
      fetchContacts();
    } catch (error) {
      console.error('Failed to import contacts:', error);
      alert('Failed to import contacts. Please try again.');
    } finally {
      setLoadingImport(false); // Конец загрузки
    }
  };

  // Логика фильтрации групп
  useEffect(() => {
    applyGroupFilters();
  }, [groups, groupNameFilter, categoryFilter]);

  const applyGroupFilters = () => {
    let tempGroups = [...groups];

    if (groupNameFilter.trim() !== '') {
      tempGroups = tempGroups.filter(group => 
        group.groupName.toLowerCase().includes(groupNameFilter.toLowerCase())
      );
    }

    if (categoryFilter.trim() !== '') {
      tempGroups = tempGroups.filter(group => group.category === categoryFilter);
    }

    setFilteredGroups(tempGroups);
  };

  // UI для списка групп
  const renderGroupsUI = () => (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Contact Groups</Typography>
        
        {/* Оборачиваем кнопки в один flex-контейнер */}
        <Box display="flex" gap={2}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={() => setOpenCreateDialog(true)}
            disabled={loadingGroups} // Отключаем кнопку во время загрузки
          >
            Create Group
          </Button>

          <Button variant="contained" component="label" color="primary" disabled={loadingGroups}>
            Import
            <input type="file" accept=".xlsx, .xls" hidden onChange={handleFileSelect} />
          </Button>
        </Box>
      </Box>
      
      {/* Если данные загружаются, показываем спиннер */}
      {loadingGroups ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Group Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Number of Emails</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
              {/* Добавляем строку фильтров */}
              <TableRow>
                <TableCell>
                  <TextField
                    value={groupNameFilter}
                    onChange={(e) => setGroupNameFilter(e.target.value)}
                    placeholder="Filter by Group Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <Select
                    value={categoryFilter}
                    onChange={(e) => setCategoryFilter(e.target.value)}
                    displayEmpty
                    variant="outlined"
                    size="small"
                    fullWidth
                  >
                    <MenuItem value="">All Categories</MenuItem>
                    {CATEGORIES.map((cat) => (
                      <MenuItem key={cat} value={cat}>
                        {cat}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredGroups.length > 0 ? (
                filteredGroups.map((group) => (
                  <TableRow 
                    key={group.groupName}
                    onClick={() => navigate(`/contacts/${group.groupName}`)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      }
                    }}
                  >
                    <TableCell>{group.groupName}</TableCell>
                    <TableCell>{group.category}</TableCell>
                    <TableCell>
                      <span>{group.totalCount} </span>
                      (<span style={{ color: 'green' }}>{group.subscribedCount}</span> / 
                       <span style={{ color: 'red' }}>{group.unsubscribedCount}</span>)
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setConfirmDeleteDialog({open:true, groupId: group.groupName});
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No groups found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Диалог создание группы */}
      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)}>
        <DialogTitle>Create New Group</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Group Name"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
          <Select
            fullWidth
            value={newGroupCategory}
            onChange={(e) => setNewGroupCategory(e.target.value)}
            displayEmpty
            style={{ marginTop: '15px' }}
          >
            <MenuItem value="" disabled>Select Category</MenuItem>
            {CATEGORIES.map(cat => (
              <MenuItem key={cat} value={cat}>{cat}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateDialog(false)} disabled={loadingGroups}>Cancel</Button>
          <Button color="primary" onClick={handleCreateGroup} disabled={loadingGroups}>
            {loadingGroups ? <CircularProgress size={24} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Диалог импорта */}
      <Dialog open={openImportDialog} onClose={() => setOpenImportDialog(false)}>
        <DialogTitle>Import Contacts</DialogTitle>
        <DialogContent>
          <Typography>
            You are about to import {importedContacts.length} contacts.
          </Typography>
          <TextField
            fullWidth
            margin="dense"
            label="Group Name"
            value={importGroupName}
            onChange={(e) => setImportGroupName(e.target.value)}
          />
          <Select
            fullWidth
            value={importCategory}
            onChange={(e) => setImportCategory(e.target.value)}
            displayEmpty
            style={{marginTop: '15px'}}
          >
            <MenuItem value="" disabled>Select Category</MenuItem>
            {CATEGORIES.map(cat => (
              <MenuItem key={cat} value={cat}>{cat}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImportDialog(false)} disabled={loadingImport}>Cancel</Button>
          <Button onClick={handleImportSubmit} color="primary" disabled={loadingImport}>
            {loadingImport ? <CircularProgress size={24} /> : 'Add Contacts'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Диалог подтверждения удаления группы */}
      <Dialog open={confirmDeleteDialog.open} onClose={() => setConfirmDeleteDialog({open:false, groupId:null})}>
        <DialogTitle>Delete Group</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this group?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteDialog({open:false, groupId:null})} disabled={loadingGroups}>Cancel</Button>
          <Button color="secondary" onClick={handleDeleteGroup} disabled={loadingGroups}>
            {loadingGroups ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );

  // UI для деталей группы (контактов)
  const renderContactsUI = () => (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      {/* Общий контейнер для заголовка и кнопок */}
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" gutterBottom>
          Contacts in Group: {groupName}
        </Typography>
        <Box display="flex" gap={2}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setOpenAddEmailDialog(true)} 
            disabled={loadingContacts}
          >
            Add Email
          </Button>
          <Button 
            variant="contained" 
            component="label" 
            color="primary" 
            disabled={loadingContacts}
          >
            Import
            <input type="file" accept=".xlsx, .xls" hidden onChange={handleFileSelectGroupImport} />
          </Button>
        </Box>
      </Box>
      
      {/* Поисковая строка */}
      <Box mb={2} display="flex">
        <TextField label="Search Email" value={search} onChange={handleSearchChange} fullWidth />
      </Box>

      {/* Если данные загружаются, показываем спиннер */}
      {loadingContacts ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextField
                    name="email"
                    label="Filter by Email"
                    value={filter.email}
                    onChange={handleFilterChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name="status"
                    label="Filter by Status"
                    value={filter.status}
                    onChange={handleFilterChange}
                    variant="outlined"
                    size="small"
                    select
                    fullWidth
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="subscribed">Subscribed</MenuItem>
                    <MenuItem value="unsubscribed">Unsubscribed</MenuItem>
                  </TextField>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedContacts.map(contact => (
                <TableRow key={contact._id}>
                  <TableCell>{contact.email}</TableCell>
                  <TableCell>
                    <Select
                      value={contact.status}
                      onChange={(e) => updateContactStatus(contact._id, e.target.value)}
                      size="small"
                      style={{
                        color: contact.status === 'unsubscribed' ? 'red' : 'black',
                        fontWeight: contact.status === 'unsubscribed' ? 'bold' : 'normal',
                      }}
                    >
                      <MenuItem value="subscribed">Subscribed</MenuItem>
                      <MenuItem value="unsubscribed">Unsubscribed</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteContact(contact._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {paginatedContacts.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>No contacts found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Пагинация */}
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination 
          count={Math.ceil(filteredContacts.length / rowsPerPage)} 
          page={page} 
          onChange={(e, value) => setPage(value)}
        />
      </Box>

      {/* Диалог добавления email вручную */}
      <Dialog open={openAddEmailDialog} onClose={() => setOpenAddEmailDialog(false)}>
        <DialogTitle>Add Emails to Group</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Emails (separated by commas)"
            value={newEmails}
            onChange={(e) => setNewEmails(e.target.value)}
            placeholder="example1@mail.com, example2@mail.com"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddEmailDialog(false)} disabled={loadingAddEmails}>Cancel</Button>
          <Button color="primary" onClick={handleAddEmails} disabled={loadingAddEmails}>
            {loadingAddEmails ? <CircularProgress size={24} /> : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Диалог импорта email в группу */}
      <Dialog open={openImportGroupDialog} onClose={() => setOpenImportGroupDialog(false)}>
        <DialogTitle>Import Contacts to Group</DialogTitle>
        <DialogContent>
          <Typography>
            You are about to import {importedGroupContacts.length} contacts into group "{groupName}".
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImportGroupDialog(false)} disabled={loadingImport}>Cancel</Button>
          <Button color="primary" onClick={handleImportGroupSubmit} disabled={loadingImport}>
            {loadingImport ? <CircularProgress size={24} /> : 'Import'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Диалог подтверждения удаления группы */}
      <Dialog open={confirmDeleteDialog.open} onClose={() => setConfirmDeleteDialog({open:false, groupId:null})}>
        <DialogTitle>Delete Group</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this group?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteDialog({open:false, groupId:null})} disabled={loadingGroups}>Cancel</Button>
          <Button color="secondary" onClick={handleDeleteGroup} disabled={loadingGroups}>
            {loadingGroups ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );

   return (
    <>
      {groupName ? renderContactsUI() : renderGroupsUI()}

      {/* Backdrop для глобальной индикации загрузки */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingGroups || loadingContacts || loadingAddEmails || loadingImport}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ContactList;
