import React from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Button, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import CampaignIcon from '@mui/icons-material/Campaign';
import ContactsIcon from '@mui/icons-material/Contacts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmailIcon from '@mui/icons-material/Email';
import DomainIcon from '@mui/icons-material/Domain';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import RequestIcon from '@mui/icons-material/ListAlt';
import RequestIcon2 from '@mui/icons-material/ViewList';

const menuIcons = {
  Home: <HomeIcon />,
  Requests: <RequestIcon />,
  'Request Form': <RequestIcon2 />,
  Campaigns: <CampaignIcon />,
  'Contact List': <ContactsIcon />,
  Dashboard: <DashboardIcon />,
  Emails: <EmailIcon />,
  Domains: <DomainIcon />,
  Templates: <StickyNote2Icon />
};

function SidebarMenu({
  menuItems,
  mobileOpen,
  toggleDrawer,
  isAuthenticated,
  handleLogout
}) {
  const isMobile = useMediaQuery('(max-width:600px)');

  // ???? ?????, ????? ?? ????????? Pricing/Login/Logout ???? ?????
  const bottomItems = isMobile
    ? [
        { text: 'Pricing', path: '/pricing', color: '#ffc800' },
        isAuthenticated
          ? { text: 'Logout', onClick: handleLogout, color: '#fff' }
          : { text: 'Login', path: '/login', color: '#fff' }
      ]
    : [];

  return (
    <Drawer
      // ?????: ?????? ?? "right", ????? ?????????? ??????
      anchor="right"
      open={mobileOpen}
      onClose={toggleDrawer(false)}
      PaperProps={{
        sx: {
          width: '80vw', // ???????????? ??????, ?????????????? ??? ?????
maxWidth: '250px',

          background: '#1f1e1f',
          color: 'white',
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }
      }}
    >
      <Box
        sx={{ p: 2, 
        overflowX: 'hidden'
        }}
  onClick={toggleDrawer(false)}
  onKeyDown={toggleDrawer(false)}
      >
        {/* ??????? ????? Drawer � ?????? ??????? ???? */}
        <List>
          {menuItems.map((item) => {
            const icon = menuIcons[item.text];
            const hasPath = !!item.path;
            return (
              <ListItem
                button
                key={item.text}
                component={hasPath ? Link : 'div'}
                to={hasPath ? item.path : undefined}
                onClick={item.onClick || null}
                sx={{
                  borderRadius: '8px',
                  my: 1,
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.15)'
                  }
                }}
              >
                {icon && (
                  <ListItemIcon sx={{ color: 'white' }}>
                    {icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{ fontWeight: 500, fontSize: '1rem', noWrap: true }}
  sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>

      {/* ?????? ????? Drawer � Pricing + Login/Logout (?????? ?? ?????????) */}
      {isMobile && (
        <Box
          sx={{
            p: 2,
            borderTop: '1px solid rgba(255,255,255,0.2)'
          }}
      >
          {bottomItems.map((item) => {
            if (item.onClick) {
              return (
                <Button
                  key={item.text}
                  onClick={item.onClick}
                  sx={{
                    color: item.color || '#fff',
                    width: '100%',
                    overflowX: 'hidden',
                    mb: 1,
                    textTransform: 'none',
                    border: '1px solid',
                    borderColor: item.color || '#fff',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  {item.text}
                </Button>
              );
            }
            return (
              <Button
                key={item.text}
                component={Link}
                to={item.path}
                sx={{
                  color: item.color || '#fff',
                  width: '100%',
                  mb: 1,
                  textTransform: 'none',
                  border: '1px solid',
                  borderColor: item.color || '#fff',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                {item.text}
              </Button>
            );
          })}
        </Box>
      )}
    </Drawer>
  );
}

export default SidebarMenu;
