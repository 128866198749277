import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';
import ListSubheader from '@mui/material/ListSubheader';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const item = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (typeof item === 'string') {
    return (
      <ListSubheader key={item} component="div" style={inlineStyle}>
        {item}
      </ListSubheader>
    );
  }

  return React.cloneElement(item, {
    style: inlineStyle,
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// ???, ??????? ???????? ???????? ??? ??????, ???? ????? resetAfterIndex ????????
function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null && typeof ref.current.resetAfterIndex === 'function') {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const ITEM_SIZE = 36;
  const ITEM_COUNT = itemData.length;
  // ?????????? ??? ???; ???? resetAfterIndex ???????????, ?????? ?? ?????????
  const gridRef = useResetCache(ITEM_COUNT);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          height={Math.min(ITEM_COUNT, 8) * ITEM_SIZE + 2 * LISTBOX_PADDING}
          width="100%"
          itemSize={ITEM_SIZE}
          itemCount={ITEM_COUNT}
          overscanCount={5}
          itemData={itemData}
          outerElementType={OuterElementType}
          ref={gridRef}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

export default ListboxComponent;
