export const getMenuItems = (authenticated) => {
  if (authenticated) {
    return [
      { text: 'Home', path: '/' },
      { text: 'Requests', path: '/requests' },
      { text: 'Request Form', path: '/requestform' },
      { text: 'Campaigns', path: '/campaigns' },
      { text: 'Contact List', path: '/contacts' },
      { text: 'Dashboard', path: '/dashboard' },
      { text: 'Emails', path: '/emails' },
      { text: 'Domains', path: '/domains' },
      { text: 'Templates', path: '/template' }
    ];
  } else {
    return [
    
    { text: 'Home', path: '/' },
    { text: 'Request Form', path: '/requestform' }
    ];
  }
};