import React, { useEffect, useState } from 'react';

const CustomInstallBanner = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // ????????????? ??????????? ????????? ????????
      e.preventDefault();
      // ????????? ??????? ??? ??????????? ??????
      setDeferredPrompt(e);
      // ?????????? ??????
      setVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () =>
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      // ?????????? ???????? ???? ?????????
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`User response: ${outcome}`);
      // ????? ?????? ???????????? ???????? ??????
      setDeferredPrompt(null);
      setVisible(false);
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: 'calc(100% - 40px)', // ????????? 20px ?? ????? ? ?????? ???????
        backgroundColor: '#333',
        color: '#fff',
        padding: '8px 16px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1000,
        boxShadow: '0px 2px 4px rgba(0,0,0,0.3)',
        fontFamily: 'sans-serif',
        fontSize: '14px'
      }}
    >
      <span style={{ marginRight: '8px', flexGrow: 1 }}>
        Install Mailsball for a better experience
      </span>
      <button
        onClick={handleInstallClick}
        style={{
          backgroundColor: 'transparent',
          color: '#fff',
          border: '1px solid #fff',
          borderRadius: '4px',
          padding: '4px 8px',
          marginRight: '8px',
          cursor: 'pointer'
        }}
      >
        Install
      </button>
      <button
        onClick={handleClose}
        style={{
          backgroundColor: 'transparent',
          color: '#fff',
          border: 'none',
          fontSize: '16px',
          cursor: 'pointer'
        }}
      >
        &#x2715;
      </button>
    </div>
  );
};

export default CustomInstallBanner;
