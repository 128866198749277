import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* ????????? ? ???? ?????????? ? ???? */}
      <Typography variant="h3" align="center" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        Effective Date: January 1, 2025
      </Typography>

      {/* 1. ???????? */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to mailsball.com, operated by Mailsball (�we�, �us�, �our�). This Privacy Policy describes how we collect, use, store, and share your personal data when you visit our website. By using our site, you agree to the terms of this Privacy Policy. If you do not agree with these terms, please do not use our website.
        </Typography>
      </Box>

      {/* 2. ?????????? ?????????? */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>2.1 Personal Data:</strong> We collect personal information you voluntarily provide when registering, placing orders, subscribing to newsletters, or contacting us. Such data may include your name, email address, phone number, mailing address, payment details, and any other information you provide.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>2.2 Non-Personal Data:</strong> We also automatically collect non-personal data such as your IP address, browser type and version, access times, URL addresses, cookies, and similar technologies.
        </Typography>
      </Box>

      {/* 3. ????????????? ?????????? */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          3. Use of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use your information to operate and improve our website, process your orders, provide customer support, send marketing communications (if you have opted-in), and comply with legal obligations.
        </Typography>
      </Box>

      {/* 4. ????? cookie ? ?????????? ???????????? */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          4. Cookies and Tracking Technologies
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies and similar technologies to enhance your browsing experience, personalize content, and analyze site usage. You can manage cookies through your browser settings, though disabling them may affect the site�s functionality.
        </Typography>
      </Box>

      {/* 5. ???????? ?????? ??????? ????? */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          5. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may share your information with trusted third parties (such as payment processors, delivery services, analytics providers, and marketing partners) that assist us in operating our website. We may also disclose your information if required by law or in connection with a merger, acquisition, or sale of assets.
        </Typography>
      </Box>

      {/* 6. ???????? ? ?????? ?????? */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          6. Data Security and Retention
        </Typography>
        <Typography variant="body1" paragraph>
          We take reasonable measures to protect your data from unauthorized access, alteration, disclosure, or destruction. Your information is retained only as long as necessary to fulfill the purposes for which it was collected or as required by law.
        </Typography>
      </Box>

      {/* 7. ????? ????????????? */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          7. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to access, correct, or delete your personal data, restrict or object to its processing, and withdraw your consent at any time (without affecting the lawfulness of processing before withdrawal). To exercise your rights, please contact us.
        </Typography>
      </Box>

      {/* 8. ?????? ?? ????????? ??????? */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          8. Third-Party Links
        </Typography>
        <Typography variant="body1" paragraph>
          Our website may contain links to third-party sites whose privacy policies differ from ours. We are not responsible for the content or practices of these sites. Please review their privacy policies before providing any personal data.
        </Typography>
      </Box>

      {/* 9. ????????? ? ???????? ?????????????????? */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          9. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Please check back periodically to stay informed about our practices.
        </Typography>
      </Box>

      {/* 10. ?????????? ?????????? */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          10. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Email:</strong>{' '}
          <Link href="mailto:info@mailsball.com">info@mailsball.com</Link>
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Address:</strong> [Insert Company Address]
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Phone:</strong> +37253591740
        </Typography>
      </Box>

      {/* ?????? */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="body2" color="textSecondary" align="center">
          � {new Date().getFullYear()} Mailsball. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
