import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Button,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, Outlet } from 'react-router-dom';
import SidebarMenu from '../components/SidebarMenu';
import { getMenuItems } from '../menuItems'; // ?????? ??????? ????

function PrivateLayout({ authenticated, handleLogout }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const menuItems = getMenuItems(authenticated);

  const toggleDrawer = (open) => () => {
    setMobileOpen(open);
  };

  const pricingButton = (
    <Button
      component={Link}
      to="/pricing"
      sx={{
        color: '#ffc800',
        textTransform: 'none',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: 'rgba(204, 133, 0, 0.08)'
        }
      }}
    >
      Pricing
    </Button>
  );

  const authButton = (
    <Button
      onClick={handleLogout}
      sx={{
        color: '#fff',
        textTransform: 'none',
        border: '1px solid #fff',
        backgroundColor: 'transparent',
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }
      }}
    >
      Logout
    </Button>
  );

  return (
    <>
      <AppBar
        position="absolute"
        sx={{
          width: '100%',
          backgroundColor: '#300259',
          boxShadow: 'none',
          zIndex: 10
        }}
      >
        <Toolbar>
          <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src="/images/logo.svg" alt="Mailsball Logo" style={{ height: '17px', marginRight: '10px' }} />
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          {!isMobile ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {menuItems.map((item) => (
                <Button
                  key={item.text}
                  component={Link}
                  to={item.path}
                  sx={{ color: '#fff', textTransform: 'none' }}
                >
                  {item.text}
                </Button>
              ))}
              {pricingButton}
              {authButton}
            </Box>
          ) : (
            <IconButton sx={{ color: '#fff', ml: 1 }} onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <SidebarMenu menuItems={menuItems} mobileOpen={mobileOpen} toggleDrawer={toggleDrawer} isAuthenticated={authenticated} handleLogout={handleLogout} />

      <Box sx={{ mt: 10, p: 2 }}>
        <Outlet />
      </Box>
    </>
  );
}

export default PrivateLayout;
