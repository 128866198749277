import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  FormControl,
  Select,
  InputLabel,
  Checkbox,
  OutlinedInput,
  MenuItem as MuiMenuItem,
  ListItemText,
  Snackbar,
  Alert,
  TableFooter,
  TablePagination
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from '@mui/material/Autocomplete';
import { getNames, getCode } from 'country-list';
import currencyCodes from 'currency-codes';

// ======== ?????? ??? ?????? (??? ? RequestForm.js) ========
const countriesList = getNames();
const currencies = currencyCodes.codes();
const services = [
  "Email Campaigns",
  "SMS Campaigns",
  "Google Ads",
  "Facebook Ads",
  "VK Ads",
  "SMM marketing"
];
const objectives = [
  "Increase sales", "Attract new customers", "Increase brand awareness",
  "Drive more website traffic", "Generate leads", "Promote a new product or service",
  "Engage the audience", "Promote special offers or discounts",
  "Grow subscribers", "Improve customer retention", "Collect feedback",
  "Improve conversion rates", "Retargeting"
];
const ageRanges = ["18-24", "25-34", "35-44", "45-54", "55-64", "65+"];
const genders = ["Male", "Female", "All"];
const contactDatabases = ["I have my own database", "Use the agency database"];

// ======== ????????? ????????? ??? ????? (??????????????) ========
const emptyFormData = {
  // ????? ????
  firstName: '',
  lastName: '',
  companyName: '',
  phoneNumber: '',
  email: '',
  chosenService: '',
  status: '',

  // Digital-marketing
  serviceSelection: '',
  campaignObjective: '',
  countries: [],
  cities: [],
  ageRange: [],
  gender: '',
  keywords: '',
  approximateBudget: '',
  currency: '',
  targetAudienceDescription: '',
  contactDatabase: '',
  additionalMessage: '',

  // Web & UX/UI & Graphic design
  graphicService: '',
  graphicDescription: '',
  graphicWebsite1: '',
  graphicWebsite2: '',
  graphicWebsite3: '',
  graphicFiles: [],

  // SEO
  seoWebpage: '',
  seoDescription: '',

  // ????????
  privacyPolicyAgreed: false
};

export default function Requests() {
  // ???????? ?????????
  const [requests, setRequests] = useState([]);
  const [filters, setFilters] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    phoneNumber: '',
    email: '',
    chosenService: '',
    status: ''
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [formData, setFormData] = useState(emptyFormData);
  const [allCities, setAllCities] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Derived: ?????????? ??????? ?? ?????? ????????? ?????
  const selectedCountryCodes = formData.countries
    .map(name => getCode(name))
    .filter(Boolean);
  const filteredCities = selectedCountryCodes.length > 0
    ? allCities.filter(city => selectedCountryCodes.includes(city.country))
    : [];

  // ====================== ???????? ?????? ======================
  useEffect(() => {
    fetch('/api/requests')
      .then(res => res.json())
      .then(data => setRequests(data))
      .catch(err => {
        console.error('Failed to fetch requests:', err);
        setErrorMessage('Failed to fetch requests.');
      });
  }, []);

  // ====================== ???????? ??????? ======================
  useEffect(() => {
    fetch('/api/cities')
      .then(response => {
        if (!response.ok) throw new Error(`HTTP error: ${response.status}`);
        return response.json();
      })
      .then(data => setAllCities(data))
      .catch(err => {
        console.error('Failed to load city data:', err);
        setErrorMessage('Failed to load city data.');
      });
  }, []);

  // ====================== ?????????? ?????? ======================
  function applyFilters(list, f) {
    return list.filter(item => {
      if (f.firstName && !item.firstName?.toLowerCase().includes(f.firstName.toLowerCase())) return false;
      if (f.lastName && !item.lastName?.toLowerCase().includes(f.lastName.toLowerCase())) return false;
      if (f.companyName && !item.companyName?.toLowerCase().includes(f.companyName.toLowerCase())) return false;
      if (f.phoneNumber && !item.phoneNumber?.toLowerCase().includes(f.phoneNumber.toLowerCase())) return false;
      if (f.email && !item.email?.toLowerCase().includes(f.email.toLowerCase())) return false;
      if (f.chosenService && !item.chosenService?.toLowerCase().includes(f.chosenService.toLowerCase())) return false;
      if (f.status && !item.status?.toLowerCase().includes(f.status.toLowerCase())) return false;
      return true;
    });
  }
  const filteredRequests = applyFilters(requests, filters);

  // ====================== ????????? (client-side) ======================
  const totalFiltered = filteredRequests.length;
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageItems = filteredRequests.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    const newVal = parseInt(event.target.value, 10);
    setRowsPerPage(newVal);
    setPage(0);
  };

  // ??????? ??? ????????? ?????????? ???????? ??? ????????
  function getUniqueValues(field) {
    return Array.from(new Set(requests.map(r => r[field]).filter(Boolean)));
  }

  // ====================== Menu actions ======================
  function handleMenuOpen(e, req) {
    setAnchorEl(e.currentTarget);
    setSelectedRequest(req);
  }
  function handleMenuClose() {
    setAnchorEl(null);
    setSelectedRequest(null);
  }

  // ====================== Delete actions ======================
  function handleDeleteConfirmOpen() {
    setDeleteDialogOpen(true);
    handleMenuClose();
  }
  function handleDeleteConfirmClose() {
    setDeleteDialogOpen(false);
  }
  async function handleDeleteRequest() {
  if (!selectedRequest) return;
  console.log(`Deleting request with ID: ${selectedRequest.id}`);
  try {
    const res = await fetch(`/api/requests/${selectedRequest.id}`, { method: 'DELETE' });
    if (!res.ok) throw new Error(`Delete error: ${res.status}`);
    setRequests(prev => prev.filter(r => r.id !== selectedRequest.id));
    setSuccessMessage('Request deleted successfully.');
  } catch (err) {
    console.error(err);
    setErrorMessage('Failed to delete request.');
  } finally {
    setDeleteDialogOpen(false);
    setSelectedRequest(null);
  }
}

  // ====================== Detail (View/Edit) actions ======================
  function handleDetailOpen() {
    if (!selectedRequest) return;
    setFormData(transformForEdit(selectedRequest));
    setDetailDialogOpen(true);
    handleMenuClose();
  }
  function handleDetailClose() {
    setDetailDialogOpen(false);
    setFormData(emptyFormData);
  }
  async function handleUpdateSubmit(e) {
    e?.preventDefault();
    if (!selectedRequest) return;
    try {
      const response = await fetch(`/api/requests/${selectedRequest.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      if (!response.ok) throw new Error(`HTTP error ${response.status}`);
      const updatedReq = await response.json();
      setRequests(prev => prev.map(r => (r.id === updatedReq.id ? updatedReq : r)));
      setSuccessMessage('Request updated successfully.');
      setDetailDialogOpen(false);
      setFormData(emptyFormData);
    } catch (err) {
      console.error('Update error:', err);
      setErrorMessage('Failed to update request.');
    }
  }

  // ====================== Handlers for formData in detail dialog ======================
  function handleChange(e) {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  }
  function handleMultiSelectChange(fieldName) {
    return (event) => {
      setFormData(prev => ({
        ...prev,
        [fieldName]: event.target.value
      }));
    };
  }
  function handleAutocompleteChange(fieldName) {
    return (event, newValue) => {
      setFormData(prev => ({
        ...prev,
        [fieldName]: newValue
      }));
    };
  }
  function handleAutocompleteChangeForCities(event, newValue) {
    const cityNames = newValue.map(cityObj => cityObj.name);
    setFormData(prev => ({
      ...prev,
      cities: cityNames
    }));
  }
  function handleFileChange(e) {
    setFormData(prev => ({
      ...prev,
      graphicFiles: Array.from(e.target.files)
    }));
  }
  function transformForEdit(request) {
    return {
      ...emptyFormData,
      ...request,
      graphicFiles: [] // ?????????? ????? ??? ??????????????
    };
  }

  return (
    <Box sx={{ px: 4, py: 6 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Requests
      </Typography>

      {/* ??????? ? ????????? ??????? */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>First Name</strong></TableCell>
              <TableCell><strong>Last Name</strong></TableCell>
              <TableCell><strong>Company</strong></TableCell>
              <TableCell><strong>Phone</strong></TableCell>
              <TableCell><strong>Email</strong></TableCell>
              <TableCell><strong>Service</strong></TableCell>
              <TableCell><strong>Status</strong></TableCell>
              <TableCell><strong>Actions</strong></TableCell>
            </TableRow>
            {/* ??? ???????? ??? ??????????? */}
            <TableRow>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('firstName')}
                  value={filters.firstName}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, firstName: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, firstName: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('lastName')}
                  value={filters.lastName}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, lastName: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, lastName: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('companyName')}
                  value={filters.companyName}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, companyName: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, companyName: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('phoneNumber')}
                  value={filters.phoneNumber}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, phoneNumber: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, phoneNumber: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('email')}
                  value={filters.email}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, email: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, email: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('chosenService')}
                  value={filters.chosenService}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, chosenService: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, chosenService: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('status')}
                  value={filters.status}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, status: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, status: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>{/* ??? ??????? ??? Actions */}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageItems.map(req => (
              <TableRow key={req.id}>
                <TableCell>{req.firstName}</TableCell>
                <TableCell>{req.lastName}</TableCell>
                <TableCell>{req.companyName}</TableCell>
                <TableCell>{req.phoneNumber}</TableCell>
                <TableCell>{req.email}</TableCell>
                <TableCell>{req.chosenService}</TableCell>
                <TableCell>{req.status || 'Not set'}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => handleMenuOpen(e, req)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {pageItems.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No requests found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={totalFiltered}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[20, 50, 100, 200, 500]}
                labelRowsPerPage="Rows per page"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/* ???? Actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDetailOpen}>View / Edit</MenuItem>
        <MenuItem onClick={handleDeleteConfirmOpen}>Delete</MenuItem>
      </Menu>

      {/* ?????? ????????????? ???????? */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteConfirmClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmClose}>Cancel</Button>
          <Button onClick={handleDeleteRequest} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* ?????? View / Edit (?????? ?????) */}
      <Dialog open={detailDialogOpen} onClose={handleDetailClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Request</DialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            onSubmit={handleUpdateSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {/* ========== Client Information ========== */}
            <Section title="Client Information">
              <TextField
                name="firstName"
                label="First name"
                fullWidth
                required
                sx={{ mb: 2 }}
                onChange={handleChange}
                value={formData.firstName}
              />
              <TextField
                name="lastName"
                label="Last name"
                fullWidth
                required
                sx={{ mb: 2 }}
                onChange={handleChange}
                value={formData.lastName}
              />
              <TextField
                name="companyName"
                label="Company name"
                fullWidth
                sx={{ mb: 2 }}
                onChange={handleChange}
                value={formData.companyName}
              />
              <TextField
                name="phoneNumber"
                label="Phone number"
                fullWidth
                required
                sx={{ mb: 2 }}
                onChange={handleChange}
                value={formData.phoneNumber}
              />
              <TextField
                name="email"
                label="Email address"
                fullWidth
                required
                sx={{ mb: 2 }}
                onChange={handleChange}
                value={formData.email}
              />
            </Section>

            {/* ========== Choose a Service ========== */}
            <Section title="Choose a Service">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Choose a Service</InputLabel>
                <Select
                  name="chosenService"
                  value={formData.chosenService}
                  onChange={handleChange}
                  label="Choose a Service"
                  required
                >
                  <MuiMenuItem value="Digital-marketing">Digital-marketing</MuiMenuItem>
                  <MuiMenuItem value="Web & UX/UI & Graphic design">
                    Web & UX/UI & Graphic design
                  </MuiMenuItem>
                  <MuiMenuItem value="SEO">SEO</MuiMenuItem>
                </Select>
              </FormControl>
              <TextField
                name="status"
                label="Status"
                fullWidth
                sx={{ mb: 2 }}
                onChange={handleChange}
                value={formData.status || ''}
              />
            </Section>

            {/* ========== Digital-marketing ========== */}
            {formData.chosenService === "Digital-marketing" && (
              <>
                <Section title="Marketing Service">
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Service Selection</InputLabel>
                    <Select
                      name="serviceSelection"
                      value={formData.serviceSelection}
                      onChange={handleChange}
                      label="Service Selection"
                      required
                    >
                      {services.map(service => (
                        <MuiMenuItem key={service} value={service}>
                          {service}
                        </MuiMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Campaign Objective</InputLabel>
                    <Select
                      name="campaignObjective"
                      value={formData.campaignObjective}
                      onChange={handleChange}
                      label="Campaign Objective"
                      required
                    >
                      {objectives.map(objective => (
                        <MuiMenuItem key={objective} value={objective}>
                          {objective}
                        </MuiMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Section>

                <Section title="Target Audience">
                  <Autocomplete
                    multiple
                    options={countriesList}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    onChange={handleAutocompleteChange('countries')}
                    value={formData.countries}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Country(ies)"
                        placeholder="Select countries..."
                      />
                    )}
                    sx={{ mb: 2, width: '100%' }}
                  />

                  <Autocomplete
                    multiple
                    options={filteredCities}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    onChange={handleAutocompleteChangeForCities}
                    value={
                      filteredCities.filter(cityObj =>
                        formData.cities.includes(cityObj.name)
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="City(ies)"
                        placeholder={
                          formData.countries.length > 0 ? "Select cities..." : "Select country first"
                        }
                      />
                    )}
                    sx={{ mb: 2, width: '100%' }}
                  />

                  <MultiSelectField
                    label="Age Range"
                    options={ageRanges}
                    value={formData.ageRange}
                    onChange={handleMultiSelectChange('ageRange')}
                  />

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      label="Gender"
                      required
                    >
                      {genders.map(g => (
                        <MuiMenuItem key={g} value={g}>
                          {g}
                        </MuiMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Section>

                <Section title="Keywords">
                  <TextField
                    name="keywords"
                    label="Keywords"
                    fullWidth
                    sx={{ mb: 2 }}
                    onChange={handleChange}
                    value={formData.keywords}
                  />
                </Section>

                <Section title="Campaign Details">
                  <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <TextField
                      name="approximateBudget"
                      label="Approximate Budget"
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      value={formData.approximateBudget}
                    />
                    <Autocomplete
                      options={currencies.sort((a, b) => {
                        const favorites = ['EUR', 'USD'];
                        const aFav = favorites.includes(a) ? 0 : 1;
                        const bFav = favorites.includes(b) ? 0 : 1;
                        if (aFav !== bFav) return aFav - bFav;
                        return a.localeCompare(b);
                      })}
                      groupBy={(option) =>
                        ['EUR', 'USD'].includes(option)
                          ? 'Popular'
                          : 'Search for your currency'
                      }
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Currency"
                          placeholder="Search for your currency"
                        />
                      )}
                      onChange={(event, newValue) =>
                        setFormData(prev => ({ ...prev, currency: newValue }))
                      }
                      value={formData.currency || ''}
                      sx={{ width: '100%' }}
                    />
                  </Box>

                  <TextField
                    name="targetAudienceDescription"
                    label="Target Audience Description"
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ mb: 2 }}
                    onChange={handleChange}
                    value={formData.targetAudienceDescription}
                  />

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Contact Database</InputLabel>
                    <Select
                      name="contactDatabase"
                      value={formData.contactDatabase}
                      onChange={handleChange}
                      label="Contact Database"
                    >
                      {contactDatabases.map(db => (
                        <MuiMenuItem key={db} value={db}>
                          {db}
                        </MuiMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Section>

                <Section title="Additional Information">
                  <TextField
                    name="additionalMessage"
                    label="Message"
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ mb: 2 }}
                    onChange={handleChange}
                    value={formData.additionalMessage}
                  />
                </Section>
              </>
            )}

            {/* ========== Web & UX/UI & Graphic design ========== */}
            {formData.chosenService === "Web & UX/UI & Graphic design" && (
              <>
                <Section title="Graphic Service">
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Graphic Service</InputLabel>
                    <Select
                      name="graphicService"
                      value={formData.graphicService}
                      onChange={handleChange}
                      label="Graphic Service"
                      required
                    >
                      <MuiMenuItem value="Web design">Web design</MuiMenuItem>
                      <MuiMenuItem value="UX/UI design">UX/UI design</MuiMenuItem>
                      <MuiMenuItem value="Graphic design">Graphic design</MuiMenuItem>
                    </Select>
                  </FormControl>
                </Section>

                {formData.graphicService && (
                  <Section title="Description">
                    <TextField
                      name="graphicDescription"
                      label="Description"
                      fullWidth
                      multiline
                      rows={4}
                      sx={{ mb: 2 }}
                      onChange={handleChange}
                      value={formData.graphicDescription}
                      required
                    />

                    {(formData.graphicService === "Web design" ||
                      formData.graphicService === "UX/UI design") && (
                      <>
                        <TextField
                          name="graphicWebsite1"
                          label="Inspiration Website 1"
                          fullWidth
                          sx={{ mb: 2 }}
                          onChange={handleChange}
                          value={formData.graphicWebsite1}
                        />
                        <TextField
                          name="graphicWebsite2"
                          label="Inspiration Website 2"
                          fullWidth
                          sx={{ mb: 2 }}
                          onChange={handleChange}
                          value={formData.graphicWebsite2}
                        />
                        <TextField
                          name="graphicWebsite3"
                          label="Inspiration Website 3"
                          fullWidth
                          sx={{ mb: 2 }}
                          onChange={handleChange}
                          value={formData.graphicWebsite3}
                        />
                      </>
                    )}

                    <Button variant="outlined" component="label" sx={{ mb: 2 }}>
                      Upload Files
                      <input
                        type="file"
                        hidden
                        multiple
                        onChange={handleFileChange}
                      />
                    </Button>
                  </Section>
                )}
              </>
            )}

            {/* ========== SEO ========== */}
            {formData.chosenService === "SEO" && (
              <Section title="Your Webpage">
                <TextField
                  name="seoWebpage"
                  label="Webpage"
                  fullWidth
                  sx={{ mb: 2 }}
                  onChange={handleChange}
                  value={formData.seoWebpage}
                  required
                />
                <TextField
                  name="seoDescription"
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                  sx={{ mb: 2 }}
                  onChange={handleChange}
                  value={formData.seoDescription}
                  required
                />
              </Section>
            )}

            {/* ========== Privacy Policy ========== */}
            <Box sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="privacyPolicyAgreed"
                    checked={!!formData.privacyPolicyAgreed}
                    onChange={handleChange}
                  />
                }
                label="I confirm that I have read and agree to the Privacy Policy"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDetailClose}>Close</Button>
          <Button variant="contained" color="primary" onClick={handleUpdateSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar ??? ?????? */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Snackbar ??? ?????? */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );

  // ================== ??????????????? ??????? ==================
  function transformForEdit(request) {
    return {
      ...emptyFormData,
      ...request,
      graphicFiles: [] // ?????????? ????? ??? ??????????????
    };
  }
  
  function applyFilters(list, f) {
    return list.filter(item => {
      if (f.firstName && !item.firstName?.toLowerCase().includes(f.firstName.toLowerCase())) return false;
      if (f.lastName && !item.lastName?.toLowerCase().includes(f.lastName.toLowerCase())) return false;
      if (f.companyName && !item.companyName?.toLowerCase().includes(f.companyName.toLowerCase())) return false;
      if (f.phoneNumber && !item.phoneNumber?.toLowerCase().includes(f.phoneNumber.toLowerCase())) return false;
      if (f.email && !item.email?.toLowerCase().includes(f.email.toLowerCase())) return false;
      if (f.chosenService && !item.chosenService?.toLowerCase().includes(f.chosenService.toLowerCase())) return false;
      if (f.status && !item.status?.toLowerCase().includes(f.status.toLowerCase())) return false;
      return true;
    });
  }
  
  function getUniqueValues(field) {
    return Array.from(new Set(requests.map(r => r[field]).filter(Boolean)));
  }
}

function Section({ title, children }) {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
}

function MultiSelectField({ label, options, value, onChange }) {
  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.map(option => (
          <MuiMenuItem key={option} value={option}>
            <Checkbox checked={value.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MuiMenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

