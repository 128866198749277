import React, { useState, useEffect } from 'react';
import { 
  TextField, Button, MenuItem, Typography, Paper, Box, 
  Select, InputLabel, FormControl, Checkbox, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  Snackbar, Alert
} from '@mui/material';
import axios from 'axios';
import juice from 'juice';

const prepareTemplate = (html, css) => {
  if (!html || !css) return html;
  return juice.inlineContent(html, css);
};

const EmailSender = () => {
  // ?????????? files ??? ?????? ??? ???????? ????????? ??????
  const [emailData, setEmailData] = useState({
    campaignName: '',
    groups: [],
    senderName: '',
    senderEmail: '',
    senderAvatar: '',
    subject: '',
    message: '',
    files: []  // ????? ????? ????????? ????????? ?????
  });

  const [groups, setGroups] = useState([]);
  const [verifiedEmails, setVerifiedEmails] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [recipientsCount, setRecipientsCount] = useState(0);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchGroups();
    fetchVerifiedEmails();
    fetchTemplates();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await axios.get('/api/contacts/groups');
      setGroups(response.data); 
    } catch (error) {
      console.error('Failed to fetch groups', error);
    }
  };

  const fetchVerifiedEmails = async () => {
    try {
      const response = await axios.get('/api/emails');
      const verified = response.data
        .filter(email => email.isVerified)
        .map(email => ({
          email: email.email,
          avatarUrl: email.avatarUrl || '',
          avatarPath: email.avatarPath || '',
        }));
      setVerifiedEmails(verified);
    } catch (error) {
      console.error('Failed to fetch verified emails', error);
      alert('Failed to load verified sender emails. Please try again later.');
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get('/api/templates');
      setTemplates(response.data);
    } catch (error) {
      console.error('Failed to fetch templates:', error);
    }
  };

  const handleSenderEmailChange = (e) => {
    const chosenEmail = e.target.value;
    const found = verifiedEmails.find(item => item.email === chosenEmail);
    setEmailData(prev => ({
      ...prev,
      senderEmail: chosenEmail,
      senderAvatar: found?.avatarPath || ''
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleGroupChange = (e) => {
    const { value } = e.target;
    setEmailData(prevData => ({
      ...prevData,
      groups: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  // ??? ?????? ?????? ??????????? FileList ? ?????? ? ????????? ????????? ?????
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setEmailData(prevData => ({
      ...prevData,
      files: [...prevData.files, ...selectedFiles]
    }));
  };

  // ???????? ????? ?? ?????? ?? ???????
  const handleRemoveFile = (index) => {
    setEmailData(prevData => {
      const updatedFiles = [...prevData.files];
      updatedFiles.splice(index, 1);
      return { ...prevData, files: updatedFiles };
    });
  };

  // ????????? email-??????? ?? ????????? ?????
  const getEmailsFromGroups = async (selectedGroups) => {
    try {
      const emails = [];
      for (const groupName of selectedGroups) {
        const response = await axios.get(`/api/contacts/groups/${groupName}`);
        if (response.data && response.data.length > 0) {
          emails.push(...response.data);
        }
      }
      return emails;
    } catch (error) {
      console.error('Failed to fetch emails from groups:', error);
      return [];
    }
  };

  // ??????? ??? ?????? ????? ? Base64 (??? ???????? data:)
  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !emailData.campaignName ||
      !emailData.senderName ||
      !emailData.senderEmail ||
      !emailData.subject ||
      !emailData.message ||
      emailData.groups.length === 0
    ) {
      alert('Please fill in all required fields.');
      return;
    }

    if (!verifiedEmails.some(verified => verified.email === emailData.senderEmail)) {
      alert('The sender email is not verified. Please use a verified email.');
      return;
    }

    setLoading(true);
    const recipients = await getEmailsFromGroups(emailData.groups);
    setLoading(false);

    if (recipients.length === 0) {
      alert('No email addresses found in the selected groups.');
      return;
    }

    setRecipientsCount(recipients.length);
    setConfirmDialogOpen(true);
  };

  const handleConfirmSend = async () => {
    setConfirmDialogOpen(false);
    setLoading(true);

    try {
      const recipients = await getEmailsFromGroups(emailData.groups);
      const combinedHtml = prepareTemplate(emailData.message, emailData.css);

      const data = {
        campaignName: emailData.campaignName,
        recipients: recipients,
        senderName: emailData.senderName,
        senderEmail: emailData.senderEmail,
        senderAvatar: emailData.senderAvatar,
        subject: emailData.subject,
        message: combinedHtml,
      };

      // ???? ??????? ?????, ?????? ?????? ???? ??? Base64 ? ????????? ????????
      if (emailData.files && emailData.files.length > 0) {
        const attachments = await Promise.all(
          emailData.files.map(async file => {
            const base64Content = await readFileAsBase64(file);
            return {
              filename: file.name,
              content: base64Content,
              encoding: 'base64'
            };
          })
        );
        data.attachments = attachments;
      }

      console.log('Sending data:', data);
      await axios.post('/api/send-email', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setSuccessSnackbarOpen(true);
      // ????? ????? ????? ???????? ????????
      setEmailData({
        campaignName: '',
        groups: [],
        senderName: '',
        senderEmail: '',
        senderAvatar: '',
        subject: '',
        message: '',
        css: '',
        files: [],
      });
      setSelectedTemplateId('');
    } catch (error) {
      console.error('Failed to send email:', error.response?.data || error.message);
      alert(`Failed to send email: ${error.response?.data?.error || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSend = () => {
    setConfirmDialogOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSuccessSnackbarOpen(false);
  };

  const handleTemplateChange = (e) => {
    const templateId = e.target.value;
    setSelectedTemplateId(templateId);

    if (!templateId) {
      setEmailData(prev => ({ ...prev, message: '', css: '' }));
      return;
    }

    const foundTemplate = templates.find(t => t._id === templateId);
    if (foundTemplate) {
      const inlineHtml = prepareTemplate(foundTemplate.html, foundTemplate.css);
      setEmailData(prev => ({
        ...prev,
        message: inlineHtml,
        css: foundTemplate.css,
      }));
    } else {
      console.error(`Template with ID ${templateId} not found`);
    }
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Send Email
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* Campaign Name */}
        <Box mb={2}>
          <TextField
            fullWidth
            label="Campaign Name"
            name="campaignName"
            value={emailData.campaignName}
            onChange={handleChange}
            variant="outlined"
          />
        </Box>

        {/* Groups */}
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Groups</InputLabel>
            <Select
              multiple
              value={emailData.groups}
              onChange={handleGroupChange}
              label="Groups"
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return 'No groups selected';
                }
                const selectedGroups = groups.filter(g => selected.includes(g.groupName));
                const totalContacts = selectedGroups.reduce(
                  (sum, g) => sum + (g.subscribedCount || 0),
                  0
                );
                const groupNames = selectedGroups.map(g => g.groupName).join(', ');
                return `${groupNames} (Total: ${totalContacts})`;
              }}
            >
              {groups.length > 0 ? (
                groups.map(group => (
                  <MenuItem key={group.groupName} value={group.groupName}>
                    <Checkbox checked={emailData.groups.indexOf(group.groupName) > -1} />
                    <ListItemText primary={`${group.groupName} (${group.subscribedCount || 0})`} />
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No groups found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>

        {/* Sender Name */}
        <Box mb={2}>
          <TextField
            fullWidth
            label="Sender Name"
            name="senderName"
            value={emailData.senderName}
            onChange={handleChange}
            variant="outlined"
          />
        </Box>

        {/* Sender Email */}
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Sender Email</InputLabel>
            <Select
              name="senderEmail"
              value={emailData.senderEmail}
              onChange={handleSenderEmailChange}
              label="Sender Email"
            >
              {verifiedEmails.length > 0 ? (
                verifiedEmails.map(({ email, avatarUrl }) => (
                  <MenuItem key={email} value={email}>
                    <Box display="flex" alignItems="center">
                      {avatarUrl && (
                        <img
                          src={avatarUrl}
                          alt="avatar"
                          style={{ width: 20, height: 20, borderRadius: '50%', marginRight: 10 }}
                        />
                      )}
                      {email}
                    </Box>
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No verified emails found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>

        {/* Subject */}
        <Box mb={2}>
          <TextField
            fullWidth
            label="Subject"
            name="subject"
            value={emailData.subject}
            onChange={handleChange}
            variant="outlined"
          />
        </Box>

        {/* Template Selection */}
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Template</InputLabel>
            <Select
              label="Template"
              value={selectedTemplateId}
              onChange={handleTemplateChange}
            >
              <MenuItem value="">No template</MenuItem>
              {templates.map(tpl => (
                <MenuItem key={tpl._id} value={tpl._id}>
                  {tpl.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Message (HTML) */}
        <Box mb={2}>
          <TextField
            fullWidth
            label="Message (HTML)"
            name="message"
            value={emailData.message}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={6}
          />
        </Box>

        {/* Files Upload */}
        <Box mb={2}>
          <Button variant="contained" component="label">
            Upload Files
            <input type="file" hidden multiple onChange={handleFileChange} />
          </Button>
          {emailData.files && emailData.files.length > 0 && (
            <Box mt={2}>
              {emailData.files.map((file, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border="1px solid #ccc"
                  borderRadius="4px"
                  p={1}
                  mb={1}
                >
                  <Typography variant="body2">{file.name}</Typography>
                  <Button variant="outlined" color="secondary" onClick={() => handleRemoveFile(index)}>
                    Remove
                  </Button>
                </Box>
              ))}
            </Box>
          )}
        </Box>

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? 'Processing...' : 'Send Email'}
        </Button>
      </form>

      <Dialog open={confirmDialogOpen} onClose={handleCancelSend}>
        <DialogTitle>Confirm Send</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to send email to {recipientsCount} recipients?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSend} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSend} color="primary" autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Email sent successfully!
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default EmailSender;
