import React, { useState } from 'react';
import { Box, Typography, Link, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SidebarMenu from './SidebarMenu'; // ?????????? SidebarMenu
import { getMenuItems } from '../menuItems'; // ?????????? ????

function Footer({ authenticated }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const menuItems = getMenuItems(authenticated);

  const toggleDrawer = (open) => () => {
    setMobileOpen(open);
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#1c1c1c',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: 4
      }}
    >
      {/* ?????? ???? ??? ????????? */}
      {isMobile ? (
        <IconButton onClick={toggleDrawer(true)} sx={{ color: 'white', mb: 3 }}>
          <MenuIcon />
        </IconButton>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 4,
            mb: 3,
          }}
        >
          {menuItems.map((item) => (
            <Link
              key={item.text}
              href={item.path}
              underline="hover"
              color="inherit"
              sx={{ fontWeight: 'bold' }}
            >
              {item.text}
            </Link>
          ))}
        </Box>
      )}

      {/* ?????????????? ????? */}
      <Box
        sx={{
          width: '80%',
          height: '1px',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          mb: 3,
        }}
      />

      {/* ??????? ? ???????? */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '80%',
          mb: 3,
        }}
      >
        <Box
          component="img"
          src="/images/logo.svg"
          alt="Mailsball Logo"
          sx={{ width: 150, mb: { xs: 2, sm: 0 } }}
        />

        <Typography variant="body2" sx={{ textAlign: 'center', color: 'rgba(255, 255, 255, 0.8)' }}>
          Copyright {'\u00A9'} 2025 Mailsball Company. All rights reserved.
        </Typography>
      </Box>

      {/* ??????? ?????? */}
      <Box
        sx={{
          backgroundColor: '#242424',
          height: '60px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box component="img" src="/images/logo2.svg" alt="Mailsball" sx={{ width: '20px' }} />
      </Box>

      {/* SidebarMenu ?????? ??? ????????? ?????? */}
      <SidebarMenu
        menuItems={menuItems}
        mobileOpen={mobileOpen}
        toggleDrawer={toggleDrawer}
      />
    </Box>
  );
}

export default Footer;
