import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Button,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SidebarMenu from '../components/SidebarMenu';
import Footer from '../components/Footer';

function HomeLayout({ authenticated, handleLogout }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  

  // ?????? ????
  const guestMenu = [
    { text: 'Home', path: '/' },
    { text: 'Request Form', path: '/requestform' }
  ];
  const authMenu = [
    { text: 'Home', path: '/' },
    { text: 'Requests', path: '/requests' },
    { text: 'Request Form', path: '/requestform' },
    { text: 'Campaigns', path: '/campaigns' },
    { text: 'Contact List', path: '/contacts' },
    { text: 'Dashboard', path: '/dashboard' },
    { text: 'Emails', path: '/emails' },
    { text: 'Domains', path: '/domains' }, 
    { text: 'Templates', path: '/template' }
  ];

  const currentMenuItems = authenticated ? authMenu : guestMenu;

  const toggleDrawer = (open) => () => {
    setMobileOpen(open);
  };

  // ?????? ?????? ?????? (???????)
  const pricingButton = (
    <Button
      component={Link}
      to="/pricing"
      sx={{
        color: '#ffc800',
        textTransform: 'none',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: 'rgba(204, 133, 0, 0.08)'
        }
      }}
    >
      Pricing
    </Button>
  );

  const authButton = authenticated ? (
    <Button
      onClick={handleLogout}
      sx={{
        color: '#fff',
        textTransform: 'none',
        border: '1px solid #fff',
        backgroundColor: 'transparent',
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }
      }}
    >
      Logout
    </Button>
  ) : (
    <Button
      component={Link}
      to="/login"
      sx={{
        color: '#fff',
        textTransform: 'none',
        border: '1px solid #fff',
        backgroundColor: 'transparent',
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }
      }}
    >
      Login
    </Button>
  );

  return (
    <>
    <Box sx={{ overflowX: 'hidden' }}>
      <AppBar
        position="absolute"
        sx={{
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          boxShadow: 'none',
          zIndex: 10
        }}
      >
        <Toolbar>
          {/* ??????? ????? */}
          <Link
            to="/"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none'
            }}
          >
            <img
              src="/images/logo.svg"
              alt="Mailsball Logo"
              style={{ height: '17px', marginRight: '10px', maxWidth: '100%' }}
            />
          </Link>

          {/* ??????, ????? ??? ????????? ???? ?????? */}
          <Box sx={{ flexGrow: 1 }} />

          {/* ???? ?? ?????????, ?????????? ?????? ???? + Pricing/Logout */}
          {!isMobile && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {currentMenuItems.map((item) => (
                  <Button
                    key={item.text}
                    component={Link}
                    to={item.path}
                    sx={{
                      color: '#fff',
                      textTransform: 'none'
                    }}
                  >
                    {item.text}
                  </Button>
                ))}
                {pricingButton}
                {authButton}
              </Box>
            </>
          )}

          {/* ?? ????????? ?????????? ?????????-?????? ?????? */}
          {isMobile && (
            <IconButton
              sx={{ color: '#fff', ml: 1 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </Box>
      {/* Drawer (??????????? ??????), ? SidebarMenu */}
      <SidebarMenu
        menuItems={currentMenuItems}
        mobileOpen={mobileOpen}
        toggleDrawer={toggleDrawer}
        isAuthenticated={authenticated}
        handleLogout={handleLogout}
      />

      <Outlet />
       <Footer authenticated={authenticated} />
    </>

  );
}

export default HomeLayout;
