import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Typography,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
  Checkbox,
  Link,
  ListItemText,
  FormControlLabel,
  Snackbar,
  IconButton,
  Alert
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ListboxComponent from './ListboxComponent';
import { getNames, getCode } from 'country-list';
import currencyCodes from 'currency-codes';
import DeleteIcon from '@mui/icons-material/Delete';
// ======== ?????? ??? ?????? ========
const countries = getNames(); // ?????? ???????? ????? (string[])
const currencies = currencyCodes.codes(); // ?????? ????? ?????
const services = [
  "Email Campaigns",
  "SMS Campaigns",
  "Google Ads",
  "Facebook Ads",
  "VK Ads",
  "SMM marketing"
];
const objectives = [
  "Increase sales", "Attract new customers", "Increase brand awareness",
  "Drive more website traffic", "Generate leads", "Promote a new product or service",
  "Engage the audience", "Promote special offers or discounts",
  "Grow subscribers", "Improve customer retention", "Collect feedback",
  "Improve conversion rates", "Retargeting"
];
const ageRanges = ["18-24", "25-34", "35-44", "45-54", "55-64", "65+"];
const genders = ["Male", "Female", "All"];
const contactDatabases = ["I have my own database", "Use the agency database"];

// ======== ????????? ????????? ????? ========
const initialFormData = {
  // ????? ????
  firstName: '',
  lastName: '',
  companyName: '',
  phoneNumber: '',
  email: '',
  chosenService: '',

  // Digital-marketing
  serviceSelection: '',
  campaignObjective: '',
  countries: [],
  cities: [],
  ageRange: [],
  gender: '',
  keywords: '',
  approximateBudget: '',
  currency: '',
  targetAudienceDescription: '',
  contactDatabase: '',
  additionalMessage: '',

  // Web & UX/UI & Graphic design
  graphicService: '',
  graphicDescription: '',
  graphicWebsite1: '',
  graphicWebsite2: '',
  graphicWebsite3: '',
  graphicFiles: [],

  // SEO
  seoWebpage: '',
  seoDescription: '',

  // ???????? ? ?????????
  privacyPolicyAgreed: false
};

function RequestForm() {
  const [formData, setFormData] = useState(initialFormData);
  const [allCities, setAllCities] = useState([]);

  // ????????? ??? Snackbar
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // ???????? ?????? ???? ??????? (??? ????? ???? ????????)
  useEffect(() => {
    fetch('/api/cities')
      .then(response => {
        if (!response.ok) throw new Error(`HTTP error: ${response.status}`);
        return response.json();
      })
      .then(data => setAllCities(data))
      .catch(err => {
        console.error('?????? ??? ???????? ???????:', err);
        setErrorMessage('Failed to load city data.');
      });
  }, []);

  // ???????? ???? ????? ?? formData.countries, ????? ??????????? ??????
  const selectedCountryCodes = formData.countries
    .map(name => getCode(name))
    .filter(Boolean);

  const filteredCities = selectedCountryCodes.length > 0
    ? allCities.filter(city => selectedCountryCodes.includes(city.country))
    : [];

  // ????????????? handleChange (????????? ????, ????????)
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  // ??? ????? Select ? ????????????? ??????? (????????, ageRange)
  const handleMultiSelectChange = (fieldName) => (event) => {
    setFormData(prev => ({
      ...prev,
      [fieldName]: event.target.value
    }));
  };

  // ??? Autocomplete, ??? ???????? ??????? ????? (????????, countries)
  const handleAutocompleteChange = (fieldName) => (event, newValue) => {
    setFormData(prev => ({
      ...prev,
      [fieldName]: newValue
    }));
  };

  // ????????? ??????? ??? ???????
  const handleAutocompleteChangeForCities = (event, newValue) => {
    // newValue � ?????? ???????? { name, country, ... }
    const cityNames = newValue.map(cityObj => cityObj.name);
    setFormData(prev => ({
      ...prev,
      cities: cityNames
    }));
  };
  
  const handleRemoveFile = (index) => {
    setFormData(prev => {
      const updatedFiles = [...prev.graphicFiles];
      updatedFiles.splice(index, 1);
      return { ...prev, graphicFiles: updatedFiles };
    });
  };

  // ????????? ?????? ??????
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    
    // ?????? ????? ? ????????? ??????
    const updatedFiles = selectedFiles.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return { file, preview: URL.createObjectURL(file), name: file.name };
    });

    setFormData(prev => ({
      ...prev,
      graphicFiles: [...prev.graphicFiles, ...updatedFiles]
    }));
  };


  // ?????? ?????: ???????? ?????? ????? FormData
  const handleSubmit = async (e) => {
    e.preventDefault();

    // 1) ??????? FormData
    const formDataToSend = new FormData();

    // 2) ????????? ????????? ???? (??????/?????)
    Object.entries(formData).forEach(([key, value]) => {
      // ??????? ????? (countries, cities, ageRange, ? ?.?.)
      if (Array.isArray(value) && key !== 'graphicFiles') {
        value.forEach(item => {
          formDataToSend.append(key, item);
        });
      } else if (key !== 'graphicFiles') {
        formDataToSend.append(key, value);
      }
    });

    // 3) ????????? ?????
     // ????????? ?????
    if (formData.graphicFiles.length > 0) {
      formData.graphicFiles.forEach(({ file }) => {
        formDataToSend.append('graphicFiles', file);
      });
    }

    // 4) ?????????? ??????
    try {
      const response = await fetch('/api/requests', {
        method: 'POST',
        body: formDataToSend
      });

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      const result = await response.json();
      console.log('Request created:', result);

      setSuccessMessage('Your request has been submitted successfully!');
      setFormData(initialFormData); // ?????????? ?????
    } catch (err) {
      console.error('Error submitting request:', err);
      setErrorMessage('Error submitting request. Please try again later.');
    }
  };

  return (
    <Box sx={{ px: { xs: 2, md: 10}, pt: 2, pb: 8 }}>
      <Typography variant="h3" sx={{ textAlign: 'center', mb: 4 }}>
        Brief Form
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center', mb: 5 }}>
        Comprehensive business growth: Email and SMS campaigns, targeted advertising, 
        SMM, graphic and UX/UI design, SEO, and sales funnel strategy.
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* ------------------- Client Information ------------------- */}
        <Section title="Client Information">
          <TextField
            name="firstName"
            label="First name"
            fullWidth
            required
            sx={{ mb: 2 }}
            onChange={handleChange}
            value={formData.firstName}
          />
          <TextField
            name="lastName"
            label="Last name"
            fullWidth
            required
            sx={{ mb: 2 }}
            onChange={handleChange}
            value={formData.lastName}
          />
          <TextField
            name="companyName"
            label="Company name"
            fullWidth
            sx={{ mb: 2 }}
            onChange={handleChange}
            value={formData.companyName}
          />
          <TextField
            name="phoneNumber"
            label="Phone number"
            fullWidth
            required
            sx={{ mb: 2 }}
            onChange={handleChange}
            value={formData.phoneNumber}
          />
          <TextField
            name="email"
            label="Email address"
            fullWidth
            required
            sx={{ mb: 2 }}
            onChange={handleChange}
            value={formData.email}
          />
        </Section>

        {/* ------------------- Choose a Service ------------------- */}
        <Section title="Choose a Service">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Choose a Service</InputLabel>
            <Select
              name="chosenService"
              value={formData.chosenService}
              onChange={handleChange}
              label="Choose a Service"
              required
            >
              <MenuItem value="Digital-marketing">Digital-marketing</MenuItem>
              <MenuItem value="Web & UX/UI & Graphic design">
                Web & UX/UI & Graphic design
              </MenuItem>
              <MenuItem value="SEO">SEO</MenuItem>
            </Select>
          </FormControl>
        </Section>

        {/* ================== Digital-marketing ================== */}
        {formData.chosenService === "Digital-marketing" && (
          <>
            <Section title="Marketing Service">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Service Selection</InputLabel>
                <Select
                  name="serviceSelection"
                  value={formData.serviceSelection}
                  onChange={handleChange}
                  label="Service Selection"
                  required
                >
                  {services.map(service => (
                    <MenuItem key={service} value={service}>
                      {service}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Campaign Objective</InputLabel>
                <Select
                  name="campaignObjective"
                  value={formData.campaignObjective}
                  onChange={handleChange}
                  label="Campaign Objective"
                  required
                >
                  {objectives.map(objective => (
                    <MenuItem key={objective} value={objective}>
                      {objective}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Section>

            <Section title="Target Audience">
              {/* ?????? (array of string) */}
              <Autocomplete
                multiple
                options={countries}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                onChange={handleAutocompleteChange('countries')}
                value={formData.countries}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Country(ies)"
                    placeholder="Select countries..."
                  />
                )}
                sx={{ mb: 2, width: '100%' }}
              />

              {/* ?????? (array of objects => array of string) */}
              <Autocomplete
                multiple
                disableListWrap
                options={filteredCities}
                getOptionLabel={(option) => option.name}
                ListboxComponent={ListboxComponent}
                filterSelectedOptions
                onChange={handleAutocompleteChangeForCities}
                value={
                  // formData.cities - ?????? ?????
                  // ????? ????????????? ?? ??????? ? ??????? ??? ??????????? ? Autocomplete
                  filteredCities.filter(cityObj =>
                    formData.cities.includes(cityObj.name)
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="City(ies)"
                    placeholder={
                      formData.countries.length > 0 ? "Select cities..." : "Select country first"
                    }
                  />
                )}
                sx={{ mb: 2, width: '100%' }}
              />

              {/* ??????? (multiple select) */}
              <MultiSelectField
                label="Age Range"
                options={ageRanges}
                value={formData.ageRange}
                onChange={handleMultiSelectChange('ageRange')}
              />

              {/* ??? */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Gender</InputLabel>
                <Select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  label="Gender"
                  required
                >
                  {genders.map(g => (
                    <MenuItem key={g} value={g}>
                      {g}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Section>

            <Section title="Keywords">
              <TextField
                name="keywords"
                label="Keywords"
                fullWidth
                sx={{ mb: 2 }}
                onChange={handleChange}
                value={formData.keywords}
              />
            </Section>

            <Section title="Campaign Details">
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                  name="approximateBudget"
                  label="Approximate Budget"
                  type="number"
                  fullWidth
                  onChange={handleChange}
                  value={formData.approximateBudget}
                />
                <Autocomplete
                  options={currencies.sort((a, b) => {
                    const favorites = ['EUR', 'USD'];
                    const aFav = favorites.includes(a) ? 0 : 1;
                    const bFav = favorites.includes(b) ? 0 : 1;
                    if (aFav !== bFav) return aFav - bFav;
                    return a.localeCompare(b);
                  })}
                  groupBy={(option) =>
                    ['EUR', 'USD'].includes(option)
                      ? 'Popular'
                      : 'Search for your currency'
                  }
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Currency"
                      placeholder="Search for your currency"
                    />
                  )}
                  onChange={(event, newValue) =>
                    setFormData(prev => ({ ...prev, currency: newValue }))
                  }
                  value={formData.currency || ''}
                  sx={{ width: '100%' }}
                />
              </Box>

              <TextField
                name="targetAudienceDescription"
                label="Target Audience Description"
                fullWidth
                multiline
                rows={4}
                sx={{ mb: 2 }}
                onChange={handleChange}
                value={formData.targetAudienceDescription}
              />

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Contact Database</InputLabel>
                <Select
                  name="contactDatabase"
                  value={formData.contactDatabase}
                  onChange={handleChange}
                  label="Contact Database"
                >
                  {contactDatabases.map(db => (
                    <MenuItem key={db} value={db}>
                      {db}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Section>

            <Section title="Additional Information">
              <TextField
                name="additionalMessage"
                label="Message"
                fullWidth
                multiline
                rows={4}
                sx={{ mb: 2 }}
                onChange={handleChange}
                value={formData.additionalMessage}
              />
            </Section>
          </>
        )}

        {/* ================== Web & UX/UI & Graphic design ================== */}
        {formData.chosenService === "Web & UX/UI & Graphic design" && (
          <>
            <Section title="Graphic Service">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Graphic Service</InputLabel>
                <Select
                  name="graphicService"
                  value={formData.graphicService}
                  onChange={handleChange}
                  label="Graphic Service"
                  required
                >
                  <MenuItem value="Web design">Web design</MenuItem>
                  <MenuItem value="UX/UI design">UX/UI design</MenuItem>
                  <MenuItem value="Graphic design">Graphic design</MenuItem>
                </Select>
              </FormControl>
            </Section>

            {formData.graphicService && (
              <Section title="Description">
                <TextField
                  name="graphicDescription"
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                  sx={{ mb: 2 }}
                  onChange={handleChange}
                  value={formData.graphicDescription}
                  required
                />

                {(formData.graphicService === "Web design" ||
                  formData.graphicService === "UX/UI design") && (
                  <>
                    <TextField
                      name="graphicWebsite1"
                      label="Inspiration Website 1"
                      fullWidth
                      sx={{ mb: 2 }}
                      onChange={handleChange}
                      value={formData.graphicWebsite1}
                    />
                    <TextField
                      name="graphicWebsite2"
                      label="Inspiration Website 2"
                      fullWidth
                      sx={{ mb: 2 }}
                      onChange={handleChange}
                      value={formData.graphicWebsite2}
                    />
                    <TextField
                      name="graphicWebsite3"
                      label="Inspiration Website 3"
                      fullWidth
                      sx={{ mb: 2 }}
                      onChange={handleChange}
                      value={formData.graphicWebsite3}
                    />
                  </>
                )}

                <Button variant="outlined" component="label" sx={{ mb: 2 }}>
                  Upload Files
                  <input
                    type="file"
                    hidden
                    multiple
                    onChange={handleFileChange}
                  />
                </Button>
                
                
             {/* ??????????? ??????????? ?????? */}
            {formData.graphicFiles.length > 0 && (
              <Box mt={2}>
                {formData.graphicFiles.map((file, index) => (
                  <Box key={index} display="flex" alignItems="center" border="1px solid #ccc" borderRadius="4px" p={1} mb={1}>
                    {file.preview && (
                      <img src={file.preview} alt="Preview" width={50} height={50} style={{ marginRight: 10 }} />
                    )}
                    <Typography variant="body2" sx={{ flexGrow: 1 }}>{file.name}</Typography>
                    <IconButton onClick={() => handleRemoveFile(index)}><DeleteIcon /></IconButton>
                  </Box>
                ))}
              </Box>
            )}
          </Section>
            )}
          </>
        )}

        {/* ================== SEO ================== */}
        {formData.chosenService === "SEO" && (
          <Section title="Your Webpage">
            <TextField
              name="seoWebpage"
              label="Webpage"
              fullWidth
              sx={{ mb: 2 }}
              onChange={handleChange}
              value={formData.seoWebpage}
              required
            />
            <TextField
              name="seoDescription"
              label="Description"
              fullWidth
              multiline
              rows={4}
              sx={{ mb: 2 }}
              onChange={handleChange}
              value={formData.seoDescription}
              required
            />
          </Section>
        )}

        {/* ================== Privacy Policy ================== */}
        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                name="privacyPolicyAgreed"
                checked={formData.privacyPolicyAgreed}
                onChange={handleChange}
                required
              />
            }
            label={
              <Typography sx={{ color: "#969696" }}>
                I confirm that I have read and agree to our&nbsp;
                <Link
                  href="https://mailsball.com/privacy-policy"
                  target="_blank"
                  rel="noopener"
                  sx={{ color: "#969696", textDecoration: "underline" }}
                >
                  Privacy Policy
                </Link>
                <span style={{ color: "red", marginLeft: "4px" }}>Required</span>
              </Typography>
            }
          />
        </Box>

        {/* ?????? ???????? */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            px: 4,
            py: 2,
            fontWeight: 'bold',
            fontSize: '1.1rem'
          }}
        >
          Submit
        </Button>
      </form>

      {/* Snackbar ??? successMessage */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Snackbar ??? errorMessage */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

// ================== ??????????????? ?????????? ==================
function Section({ title, children }) {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
}

function MultiSelectField({ label, options, value, onChange }) {
  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default RequestForm;
