import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';

// Layouts
import HomeLayout from './layouts/HomeLayout';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout';

// ??????????
import CustomInstallBanner from './CustomInstallBanner';
import PrivateRoute from './components/PrivateRoute';

// ?????? ???????
import Home from './components/Home';
import Login from './components/Login';
import Campaigns from './components/Campaigns';
import ContactList from './components/ContactList';
import Domains from './components/Domains';
import Emails from './components/Emails';
import Dashboard from './components/Dashboard';
import Template from './components/Template';
import RequestForm from './components/RequestForm';
import Requests from './components/Requests';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    axios
      .get('/api/auth/check-session', { withCredentials: true })
      .then((res) => {
        if (res.data && res.data.authenticated) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
      })
      .catch((err) => {
        console.error('Failed to check session:', err);
        setAuthenticated(false);
      })
      .finally(() => {
        setLoadingAuth(false);
      });
  }, []);

  const handleLogout = () => {
    axios
      .post('/api/auth/logout', {}, { withCredentials: true })
      .then(() => {
        setAuthenticated(false);
        window.location.href = '/';
      })
      .catch((err) => {
        console.error('Logout error:', err);
      });
  };

  if (loadingAuth) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <CustomInstallBanner />
      <Routes>
        {/* 1) ???????? ???????? ??? HomeLayout */}
        <Route
          path="/"
          element={
            <HomeLayout
              authenticated={authenticated}
              handleLogout={handleLogout}
            />
          }
        >
          <Route index element={<Home />} />
        </Route>
        
        <Route
          path="/requestform"
          element={
            <PublicLayout
              authenticated={authenticated}
              handleLogout={handleLogout}
            />
          }
        >
          <Route index element={<RequestForm />} />
        </Route>
        
        <Route
          path="/privacy-policy"
          element={
            <PublicLayout
              authenticated={authenticated}
              handleLogout={handleLogout}
            />
          }
        >
          <Route index element={<PrivacyPolicy />} />
        </Route>
        
        {/* 2) ????????? ???????? ??? PublicLayout */}
        <Route
          element={
            <PublicLayout
              authenticated={authenticated}
              handleLogout={handleLogout}
            />
          }
        >
          <Route path="/login" element={<Login />} />
        </Route>

        {/* 3) ????????? ???????? ??? PrivateLayout */}
        <Route
          element={
            <PrivateLayout
              authenticated={authenticated}
              handleLogout={handleLogout}
            />
          }
        >
          <Route
            path="/campaigns"
            element={
              <PrivateRoute
                element={<Campaigns />}
                isAuthenticated={authenticated}
              />
            }
          />
          
          <Route
            path="/requests"
            element={
              <PrivateRoute
                element={<Requests />}
                isAuthenticated={authenticated}
              />
            }
          />
          
          <Route
            path="/contacts"
            element={
              <PrivateRoute
                element={<ContactList />}
                isAuthenticated={authenticated}
              />
            }
          />
          <Route
            path="/contacts/:groupName"
            element={
              <PrivateRoute
                element={<ContactList />}
                isAuthenticated={authenticated}
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                element={<Dashboard />}
                isAuthenticated={authenticated}
              />
            }
          />
          <Route
            path="/emails"
            element={
              <PrivateRoute
                element={<Emails />}
                isAuthenticated={authenticated}
              />
            }
          />
          <Route
            path="/domains"
            element={
              <PrivateRoute
                element={<Domains />}
                isAuthenticated={authenticated}
              />
            }
          />
          <Route
            path="/template"
            element={
              <PrivateRoute
                element={<Template />}
                isAuthenticated={authenticated}
              />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
